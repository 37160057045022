@import "styles/utils/mixins";

.wrapper {
  width: 100%;
  height: auto;
  position: relative;

  .play {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: var(--brand-color-key);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      filter: brightness(90%);
    }
  }
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 65px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.video-wrapper {
  height: 100%;
  width: 100%;
  pointer-events: auto;
  background-color: rgb(24 22 44 / 90%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

.video {
  width: 100%;
  height: auto;
  max-width: 1200px;
  background: #fff;
  padding: 40px 75px;
  box-sizing: border-box;
  position: relative;
  margin: auto 0;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .close-icon {
    --native-icon-fill: var(--brand-color-key);
  }
}

@include small-screen {
  .video-wrapper {
    padding: 0;
  }

  .video {
    padding: 60px 0 0;
    background-color: transparent;
  }

  .content {
    padding: 30px;
  }

  .close {
    background-color: #fff;
  }
}
