@import "styles/utils/mixins";

.wrapper {
  background-color: #efeff3;
  padding: 85px 0;
  width: 100%;
}

.inner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: flex-start;
}

.content {
  width: 100%;
  position: sticky;
  top: 100px;
  padding-right: 90px;
}

.media {
  width: 100%;
  height: auto;
}

.title {
  margin-bottom: 110px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  display: grid;
  grid-template-columns: 100px minmax(0, 1fr);
  padding-bottom: 45px;

  &:not(:first-child) {
    padding-top: 45px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #c8c7cf;
  }
}

@include small-screen {
  .wrapper {
    padding: 40px 0;
  }

  .inner {
    grid-template-columns: 1fr;
    grid-gap: 45px;
  }

  .title {
    margin-bottom: 40px;
  }

  .content {
    position: static;
    padding-right: 0;
  }

  .list-item {
    font-size: 16px;
    line-height: 20px;
    grid-template-columns: 60px minmax(0, 1fr);
    padding-bottom: 25px;

    &:not(:first-child) {
      padding-top: 25px;
    }
  }
}
